<template>
  <div>
    <div class="flex justify-start items-center">
      <div class="md:w-6/12 flex justify-start">
        <img
          class="w-max h-32 ml-28 mt-0 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          v-if="pet.type !== 3"
          :titlemenu1="$t('account_dropdown_menu_pet_records')"
          :to1="'/petoverview/new'"
          :titlemenu2="title2"
          :to2="'/petrecord'"
          :titlemenu3="$t('pet_dropdown_menu_care_blog')"
          class="pl-4"
        />
        <HeaderGlobal
          v-else
          :titlemenu1="title2"
          :to1="'/petrecord'"
          :titlemenu2="$t('pet_dropdown_menu_care_blog')"
          class="pl-4"
        />
      </div>
    </div>
    <div
      v-if="pet"
      class="w-11/12 mt-8 mx-auto flex flex-col lg:flex-row items-center justify-between flex-wrap"
    >
      <AppointmentSection
        class="mb-8 block lg:hidden"
        v-if="!toggleComment && !toggleExternal"
        :pet="pet"
      />
      <!-- :pet=pet "this.$route.params.data" -->
      <!--   <ExternalSection
        class="block lg:hidden"
        @expandExternal="toggleExternalExpand"
        v-if="!toggleComment"
        :pet="pet"
      /> -->
      <!--  <RoutinesComment
        class="mt-8 lg:mt-auto"
        @expandComment="toggleCommentExpand"
        v-if="!toggleExternal"
        :pet="pet"
      />
      <AppointmentSection
        class="hidden lg:block"
        v-if="!toggleComment && !toggleExternal"
      /> -->
      <ExternalSection
        class="mt-8 hidden lg:block"
        @expandExternal="toggleExternalExpand"
        v-if="!toggleComment"
        :pet="pet"
      />
    </div>
    <div v-else class="mt-10 text-center font-proximaMedium">
      No Data. <br />
      Go back to Pet Records and select the pet.
    </div>
    <AddNoteModals
      :petsOwned="petsOwned"
      :diary="false"
      :pet="this.$route.params.data"
    />
    <AddAppointmentModals :pet="this.$route.params.data" />
    <!-- <AddGoogleMap /> -->
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import RoutinesComment from "./routinescomment/RoutinesComment.vue";
import AppointmentSection from "./appointments/AppointmentSection.vue";
import ExternalSection from "./externalentries/ExternalSection.vue";

import AddNoteModals from "./modals/AddNoteModals.vue";
import AddAppointmentModals from "./modals/AddAppointmentModals.vue";

export default {
  components: {
    HeaderGlobal,
    RoutinesComment,
    AppointmentSection,
    ExternalSection,
    AddNoteModals,
    AddAppointmentModals,
  },
  data() {
    return {
      pet: {},
      title2: "",
      toggleComment: false,
      toggleExternal: true,
      petsOwned: [
        { name: "Rocky Rocky", image: "dog_icon" },
        { name: "Milo", image: "other_icon" },
        { name: "Buddy", image: "cat_icon" },
        { name: "Bear", image: "ferret_icon" },
      ],
    };
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);

    this.title2 = this.pet
      ? this.pet.species.id == 1
        ? this.$t("global_dog") + " Record: " + this.pet.callName
        : this.pet.species.id == 2
        ? this.$t("global_cat") + " Record: " + this.pet.callName
        : this.pet.species.id == 3
        ? this.$t("global_ferret") + " Record: " + this.pet.callName
        : this.pet.species.id == 4
        ? this.$t("global_bird") + " Record: " + this.pet.callName
        : this.$t("global_other") + " Record: " + this.pet.callName
      : "";
  },
  methods: {
    toggleCommentExpand() {
      this.toggleComment = !this.toggleComment;
    },
    toggleExternalExpand() {
      this.toggleExternal = !this.toggleExternal;
    },
  },
};
</script>

<style></style>
