<template>
  <div class="w-full lg:bg-lightBlue rounded-br-2xl">
    <div class="block lg:hidden flex justify-end">
      <img
        class="cursor-pointer"
        ref="prefetch"
        src="@/assets/images/expand-icon.png"
        alt="expand-icon"
        @click="expandExternal"
      />
    </div>
    <div class="flex items-start relative">
      <div class="lg:p-8 flex flex-wrap justify-between">
        <EntriesEach
          v-for="(data, i) in careBlogss"
          :key="i"
          :data="data"
          :pet="pet"
          :color="color[i]"
          :expanded="expanded"
          @editModals="openEditModals(data)"
        />
      </div>
      <img
        v-if="pet.type !== 3"
        class="hidden lg:block pt-12 pr-4 cursor-pointer"
        ref="prefetch"
        src="@/assets/images/expand-icon.png"
        alt="expand-icon"
        @click="expandExternal"
      />
    </div>
    <EditNoteModals
      :toggleEditModals="toggleEditModals"
      :data="data"
      :id="id"
      :pets="pets"
      :title="title"
      :description="description"
      :mood="mood"
      :keyword="keyword"
      :petsOwned="petsOwned"
      :relevantPets="relevantPets"
      @closeEditModals="closeEditModals"
    />
  </div>
</template>
<script>
import EntriesEach from "./EntriesEach.vue";
import EditNoteModals from "../modals/EditNoteModals.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    EntriesEach,
    EditNoteModals,
  },
  data() {
    return {
      pet: {},
      expanded: false,
      pets: [],
      toggleEditModals: false,
      title: "",
      description: "",
      relevantPets: [],
      mood: 0,
      keyword: [],
      data: {},
      color: [
        "pastelYellow",
        "pastelOrange",
        "pastelOrange",
        "pastelBlue",
        "pastelBlue",
        "pastelYellow",
        "pastelYellow",
        "pastelOrange",
        "pastelBlue",
        "pastelYellow",
        "pastelYellow",
        "pastelOrange",
        "pastelOrange",
        "pastelBlue",
        "pastelBlue",
        "pastelYellow",
        "pastelYellow",
        "pastelOrange",
        "pastelBlue",
        "pastelYellow",
        "pastelYellow",
        "pastelOrange",
        "pastelOrange",
        "pastelBlue",
        "pastelBlue",
        "pastelYellow",
        "pastelYellow",
        "pastelOrange",
        "pastelBlue",
        "pastelYellow",
        "pastelYellow",
        "pastelOrange",
        "pastelOrange",
        "pastelBlue",
        "pastelBlue",
        "pastelYellow",
        "pastelYellow",
        "pastelOrange",
        "pastelBlue",
        "pastelYellow",
      ],

      petsOwned: [
        { name: "Rocky Rocky", image: "dog_icon" },
        { name: "Milo", image: "other_icon" },
        { name: "Buddy", image: "cat_icon" },
        { name: "Bear", image: "ferret_icon" },
      ],
      id: 0,
    };
  },
  computed: {
    ...mapGetters({
      careBlogs: "appointment/getCareBlogs",
    }),
    careBlogss() {
      console.log(careBlogs);
      return this.pet.type == 3 || this.pet.type == 4
        ? this.careBlogs.own_entries
        : localStorage.getItem("role") == 1
        ? [...this.careBlogs.own_entries, this.careBlogs.others_entries]
        : localStorage.getItem("role") == 3
        ? this.careBlogs.care_blog_entries
        : [];
    },
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);

    console.log(this.pet);
    this.expanded = this.pet.type == 3 ? true : this.expanded;

    this.getCareBlogs(this.pet.petID);
  },

  methods: {
    ...mapActions({
      getCareBlogs: "appointment/getCareBlogs",
    }),

    expandExternal() {
      this.expanded = !this.expanded;
      console.log(this.expanded);
      this.$emit("expandExternal");
    },
    openEditModals(i) {
      this.data = i;
      this.pets = i.pets;
      this.title = i.title;
      this.description = i.description;
      this.relevantPets = i.relevantPets;
      this.mood = i.mood;
      this.keyword = i.diary_tags;
      this.toggleEditModals = true;
      this.id = i.id;
    },
    closeEditModals() {
      this.toggleEditModals = false;
    },
  },
};
</script>

<style></style>
