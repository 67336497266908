<template>
  <div
    class="w-full p-4 bg-lightBlue rounded-br-2xl"
    :class="[compWidth, compHeight]"
  >
    <div class="text-left flex items-center justify-between">
      <div class="bg-primary-20 px-4 py-5 rounded-br-2xl">
        All activities were completed within compliance guidelines.
      </div>
      <img
        @click="expandComment"
        class="cursor-pointer"
        ref="prefetch"
        src="@/assets/images/expand-icon.png"
        alt="expand-icon"
      />
    </div>
    <div class="overflow-auto" :class="compHeightTwp">
      <CommentEach
        v-for="(data, i) in compliances"
        :key="i"
        :data="data"
        @openCommentModals="previewComment(data.message)"
      />
    </div>
    <ModalsConfirm :show="previewCommentModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
          @click="previewCommentModals = false"
        >
          ✖
        </div>
        <div>
          <img
            class="m-auto h-24 lg:h-auto"
            rel="prefetch"
            src="@/assets/images/diary-bg-icon.png"
            alt="diary-bg-icon"
          />
        </div>
        <h1 class="mt-4 text-lg lg:text-2xl">
          Jill’s Comment on Rocky’s Routine
        </h1>
      </div>
      <div
        slot="actionText"
        class="my-6 bg-pastelBlue p-6 rounded-lg flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <p>
          {{ previewCommentContent }}
        </p>
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative"></div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative"></div>
    </ModalsConfirm>
  </div>
</template>

<script>
import CommentEach from "./CommentEach.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CommentEach,
    ModalsConfirm,
  },
  data() {
    return {
      previewCommentModals: false,
      previewCommentContent: "",
      expanded: false,
      commentWidth: "lg:w-49perc",
      commentHeight: "lg:h-100",
    };
  },
  computed: {
    ...mapGetters({
      compliances: "appointment/getCompliances",
    }),

    compWidth() {
      if (this.expanded) {
        return "lg:w-full";
      } else {
        return "lg:w-49perc";
      }
    },
    compHeight() {
      if (this.expanded) {
        return "lg:h-full";
      } else {
        return "lg:h-100";
      }
    },
    compHeightTwp() {
      if (this.expanded) {
        return "lg:h-full";
      } else {
        return "lg:h-80";
      }
    },
  },
  mounted() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);

    this.getCompliances(this.pet.petID);
  },
  methods: {
    ...mapActions({
      getCompliances: "appointment/getCompliances",
    }),
    expandComment() {
      this.expanded = !this.expanded;
      this.$emit("expandComment");
    },
    previewComment(i) {
      this.previewCommentModals = true;
      this.previewCommentContent = i;
    },
  },
};
</script>

<style>
</style>