<template>
  <div
    class="mt-8 lg:mt-0 p-4 w-full lg:w-49perc md:h-100 rounded-br-2xl bg-lightBlue"
  >
    <h5 class="text-lg font-proximaMedium">Appointments</h5>
    <div class="mt-4 font-proximaLight">
      <ul
        class="p-4 bg-white flex items-center justify-between font-proximaMedium"
      >
        <li class="w-2/12">Time</li>
        <li class="w-3/12">{{ $t("global_date") }}</li>
        <li class="w-5/12">Location</li>
        <li class="w-2/12">Tag</li>
      </ul>
      <div class="h-64 overflow-auto">
        <ul
          class="p-4 text-xs lg:text-sm flex items-center justify-between border-b border-primary"
          v-for="(data, i) in this.appointments"
          :key="i"
        >
          <li class="w-2/12">{{ data.time_appointment }}</li>
          <li class="w-3/12">{{ data.date_appointment }}</li>
          <li class="w-5/12">
            <img
              class="m-auto"
              ref="prefetch"
              src="@/assets/images/map-pin.svg"
              alt="map-pin-icon"
            />
          </li>
          <li class="w-2/12">{{ data.description }}</li>
        </ul>
      </div>
    </div>
    <div class="mt-4 md:hidden">
      <h5 class="mb-4 text-lg font-proximaMedium">Reminders</h5>
      <div class="bg-white p-2 rounded-md h-64 overflow-auto">
        <ReminderEach
          v-for="(data, i) in remindersData"
          :key="i"
          :data="data"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ReminderEach from "./ReminderEach.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    pet: {},
  },
  components: {
    ReminderEach,
  },
  data() {
    return {
      appointmentData: [
        {
          time: "9:00",
          date: "08-12-2021",
          location: "Janas Tierarztpraxis",
          tag: "vet",
        },
      ],
      remindersData: [
        { checked: true, title: "Feed Rocky", time: "Today, 09:00" },
        {
          checked: false,
          title: "Take out for a walk",
          time: "Daily at 14:10",
        },
        { checked: false, title: "Book grooming", time: "Tomorrow, 12:00" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      appointments: "appointment/getAppointments",
    }),
  },
  created() {
    console.log("***************");
    this.pet ? this.getAppointments(this.pet.petID) : "";
  },
  methods: {
    ...mapActions({
      getAppointments: "appointment/getAppointments",
    }),
  },
};
</script>

<style></style>
