<template>
  <div class="my-6">
    <CareblogSectionNonUser v-if="pet.type == 3" />
    <CareblogSection v-else />
    <NavMenuSecondary page="careblog" />
  </div>
</template>

<script>
import CareblogSection from "../components/careblog/CareblogSection.vue";
import CareblogSectionNonUser from "../components/careblog/CareblogSectionNonUser.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    CareblogSection,
    CareblogSectionNonUser,
    NavMenuSecondary,
  },
  data() {
    return {
      pet: {},
    };
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);
  },
};
</script>
