<template>
  <div
    class="w-full my-3 p-4 text-left font-proximaLight rounded-br-2xl relative"
    :class="['bg-' + color, expandEntry]"
  >
    <img
      v-if="pet.type !== 3"
      class="hidden md:block absolute top-2 right-2 cursor-pointer"
      src="@/assets/images/edit-md-icon.png"
      alt="edit-md-icon"
      @click="$emit('editModals')"
    />

    <h5 class="mb-2 text-lg font-proximaMedium">{{ data.title }}</h5>
    <p>
      {{ data.description }}
    </p>
    <ul class="my-3 flex">
      <li
        class="px-4 py-1 mr-1 w-max text-sm bg-primary text-white rounded-sm"
        v-for="(e, i) in data.diary_tags"
        :key="i"
      >
        {{ e }}
      </li>
    </ul>
    <div
      class="font-proximaMedium text-xs lg:text-sm flex items-center justify-between"
    >
      <span>{{ dateDairy }}</span>
      <span class="uppercase">{{ timeDairy }}</span>
      <div class="flex items-center justify-between">
        <span>{{ $t("global_mood") }} </span>
        <component :is="whichIcon" width="20" height="20" class="ml-2" />
      </div>

      <div class="hidden md:flex items-center">
        <span>{{ data.place }}</span>
        <img class="ml-2" src="@/assets/images/pp-1.png" alt="pp-1" />
      </div>
      <img
        v-if="pet.type !== 3"
        class="md:hidden cursor-pointer"
        src="@/assets/images/edit-md-icon.png"
        alt="edit-md-icon"
        @click="$emit('editModals')"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EmojiSad from "../../icons/emojiColor/EmojiSad.vue";
import EmojiLaugh from "../../icons/emojiColor/EmojiLaugh.vue";
import EmojiSmile from "../../icons/emojiColor/EmojiSmile.vue";
import EmojiSadSlight from "../../icons/emojiColor/EmojiSadSlight.vue";
import EmojiMeh from "../../icons/emojiColor/EmojiMeh.vue";
import EmojiHeartEyes from "../../icons/emojiColor/EmojiHeartEyes.vue";
import EmojiAngry from "../../icons/emojiColor/EmojiAngry.vue";

export default {
  props: ["data", "expanded", "color", "pet"],
  components: {
    EmojiSad,
    EmojiLaugh,
    EmojiSmile,
    EmojiSadSlight,
    EmojiMeh,
    EmojiHeartEyes,
    EmojiAngry,
  },

  computed: {
    whichIcon() {
      if (this.data.mood == 1) return "EmojiHeartEyes";
      else if (this.data.mood == 2) return "EmojiLaugh";
      else if (this.data.mood == 3) return "EmojiSmile";
      else if (this.data.mood == 4) return "EmojiSadSlight";
      else if (this.data.mood == 5) return "EmojiMeh";
      else if (this.data.mood == 6) return "EmojiSad";
      else if (this.data.mood == 7) return "EmojiAngry";
    },
    expandEntry() {
      if (this.expanded) {
        return "lg:w-full";
      } else {
        return "lg:w-49perc";
      }
    },
    dateDairy() {
      return moment(String(this.data.date_created)).format("dddd, DD MMM YYYY");
    },
    timeDairy() {
      return moment(String(this.data.date_created)).format("hh:mm a");
    },
  },
};
</script>

<style></style>
