<template>
  <div class="flex items-center">
    <img
      class="h-5"
      src="@/assets/images/success-icon.png"
      alt="success-icon"
      v-if="data.checked"
    />
    <div class="h-5 w-5 border border-lightGrey40 rounded-full bg-lightGrey20" v-else></div>
    <div class="ml-4 mt-2 text-left">
      <h5 class="text-sm font-proximaMedium">{{ data.title }}</h5>
      <span class="text-xs font-proximaLight">{{ data.time }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style>
</style>