<template>
  <div
    class="
      text-left
      px-4
      py-5
      my-4
      flex
      items-center
      justify-between
      rounded-br-2xl
    "
    :class="'bg-' + showClor"
  >
    <h5>
      {{ data.message }}
    </h5>
    <img
      class="cursor-pointer"
      v-if="showDiaryIcon"
      @click="$emit('openCommentModals')"
      ref="prefetch"
      src="@/assets/images/diary-icon.png"
      alt="diary-icon"
    />
  </div>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    showDiaryIcon() {
      if (this.data.color == "pasterRed-40") {
        return false;
      } else {
        return true;
      }
    },
    showClor() {
      if (this.data.type == 1) {
        return "lightGrey20";
      } else if (this.data.type == 2) {
        return "primary-20";
      } else {
        return "pasterRed-40";
      }
    },
  },
};
</script>

<style>
</style>